<template>
  <div class="schedaDocumento" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs11 md8 lg5>
          <v-toolbar color="secondary">
              <v-toolbar-title class="white--text">
                  Scheda Documento
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <template v-if="isMaster">
                <v-btn small icon @click="remove_documento()" color="white">
                  <v-icon small>fas fa-trash</v-icon>
                </v-btn>
                <v-btn small icon @click="isEditing = !isEditing" color="white" class="ml-3">
                  <v-icon small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
          </v-toolbar>
          <v-card outlined>
              <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                  <v-container>
                      <v-layout column>
                          <v-text-field
                              :readonly="!isEditing"
                              label="Titolo"
                              v-model="titolo"
                              required
                              :rules="testoRules"
                          ></v-text-field>
                          <v-file-input
                              v-if="isMaster"
                              :disabled="!isEditing"
                              accept=".pdf,.png,.jpg,.jpeg" 
                              label="Documento"
                              v-model="documento"
                              :rules="documentoRules"
                              prepend-inner-icon="fas fa-paperclip"
                              prepend-icon=""
                              @change="documentChange = true"
                              truncate-length="25"
                          ></v-file-input>
                          <v-menu
                            v-if="isMaster"
                            ref="menuDataScadenza"
                            v-model="menuDataScadenza"
                            :close-on-content-click="false"
                            :return-value.sync="dataScadenza"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                autocomplete="off"
                                v-model="dataScadenza"
                                label="Data Scadenza"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="isEditing ? on : undefined"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="dataScadenza"
                              no-title
                              scrollable
                              locale="it"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="menuDataScadenza = false"
                              >
                                Chiudi
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menuDataScadenza.save(dataScadenza)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-btn 
                              v-if="linkDocumento"
                              color="secondary" 
                              class="mx-16 mb-5"
                              :href="endpointServer + linkDocumento"
                              target="_BLANK"
                          >Vedi</v-btn> 
                          <center v-if="!linkDocumento" class="mb-5"><b>Documento Eliminato o Inesistente</b></center>
                          <v-textarea
                              :readonly="!isEditing"
                              label="Note"
                              v-model="note"
                              outlined
                              auto-grow
                              rows="1"
                          ></v-textarea>
                          <v-select
                              v-if="isMaster"
                              :items="lista_permessi"
                              v-model="permessi"
                              multiple
                              required
                              filled
                              :rules="requiredRules"
                              label="Permessi"
                              class="mb-0"
                          ></v-select>
                          <v-select
                              v-if="isMaster"
                              :readonly="!isEditing"
                              :items="lista_clienti"
                              v-model="cliente"
                              outlined
                              label="Cliente"
                              class="mb-0"
                              no-data-text="Nessun Cliente"
                              item-text="nominativo1"
                              item-value="_id"
                          ></v-select>
                      </v-layout>
                  </v-container>
              </v-form>
              <v-card-actions>
                <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                  <v-btn color="primary" width="100%" @click="submit" :disabled="isEditing && !formValid && loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn> 
                </v-col>
              </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import apiUtenti from '@/js/pages/utenti';
import apiDocumenti from '@/js/pages/documenti';
import { testoRules, requiredRules, documentoRules } from '@/js/validationRules';

export default {
  name: 'schedaDocumento',
  data() {
    return {
      token: this.$cookies.get('token'),
      _id: this.$route.query._id,
      isEditing: false,
      titolo: '',
      note: '',
      cliente: undefined,
      permessi: undefined,
      documento: undefined,
      menuDataScadenza: false,
      dataScadenza: '',
      linkDocumento: '',
      lista_clienti: [],
      formValid: false,
      testoRules: testoRules,
      requiredRules: requiredRules,
      documentoRules: documentoRules,
      loading: false,
      documentChange: false,
      lista_permessi: ['Master', 'Tecnico', 'Partner', 'Cliente'],
      isMaster: false,
      endpointServer: process.env.VUE_APP_ENDPOINT_API || ''
    }
  },
  async created() {
    if(this.$store.state.ruolo == 'Cliente') this.isMaster = false;
    else this.isMaster = true;

    this.lista_clienti = await apiUtenti.getAll(this.token, 'Cliente');
    if(!this._id) this._id = this.$route.query._id;
    let documento = await apiDocumenti.getOne(this._id);
    this.titolo = documento.titolo || '';
    this.dataScadenza = documento.data_scadenza ? new Date(documento.data_scadenza).toISOString().substr(0, 10) : '';
    this.note = documento.note || '';
    this.cliente = documento.id_cliente;
    this.permessi = documento.permessi;
    let nomeFile = '';
    if(documento.file){
      this.linkDocumento = documento.file;
      nomeFile = documento.file.substring(documento.file.lastIndexOf("/")+1);
      nomeFile = nomeFile.substring(0, nomeFile.lastIndexOf("_")) + nomeFile.substring(nomeFile.lastIndexOf("."));
    }
    this.documento = {
      name: nomeFile,
      size: 10
    };
  },
  methods: {
    async submit(){
      if(this.isEditing){
        this.loading = true;
        if(
          this.$refs.form_data.validate() &&
          this.formValid
        ){
          let sendFile = await this.sendFile();
          if(sendFile) {
            let data = {
              _id: this._id,
              titolo: this.titolo,
              data_scadenza: this.dataScadenza,
              note: this.note,
              id_cliente: this.cliente,
            };
            await apiDocumenti.updateOne(this.token, data);
            this.$router.push('/listDocumenti');
          }
        }
        this.loading = false;
      } else this.$router.back();
    },
    async remove_documento(){
      if(confirm("Eliminare il documento?")){
        let data = { _id: this._id };
        await apiDocumenti.deleteOne(this.token, data);
        this.$router.push('/listDocumenti');
      }
    },
    async sendFile() {
      if(this.documentChange){
        if(this.documento) {
          let formData = new FormData();
          formData.append("documento", this.documento);
          return await apiDocumenti.sendFile(this.token, this._id, formData);
        } else return false;
      } else return true;
    },
  },
}
</script>
