import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/documenti/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/documenti/get_all?token="+token).then(response => {
            return response.data;
        });
    },
    async getAllMine(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/documenti/get_all_mine?token="+token).then(response => {
            return response.data;
        });
    },
    async getOne(_id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/documenti/get_one?_id="+_id).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/documenti/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/documenti/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async sendFile(token, id_documento, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/documenti/send_file?token="+token+"&id_documento="+id_documento, params).then(response => {
            return response.data;
        });
    },
}